import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // Create an observable of Auth0 instance of client
    auth0Client$ = (from(
        createAuth0Client({
            domain: environment.auth0.domain,
            client_id: environment.auth0.clientId,
            redirect_uri: `${window.location.origin}/#/app/dashboard`,
            audience: `https://${environment.auth0.domain}/userinfo`,
            params: {
                scope: 'openid profile email'
            }
        })
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    );
    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap(res => {
            this.loggedIn = res
        })
    );
    handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );
    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    public userProfile$ = this.userProfileSubject$.asObservable();
    // Create a local property for login status
    loggedIn: boolean = null;

    constructor(
        private _router: Router,
        private _http: HttpClient
    ) { }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options))),
            tap(user => this.userProfileSubject$.next(user))
        );
    }

    private userDataSubject$ = new BehaviorSubject<any>(null);
    public userData$ = this.userDataSubject$.asObservable();

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
    getTokenSilently$(options?:any): Observable<string> {
        return this.auth0Client$.pipe(
            concatMap((client: any) => from<string>(client.getTokenSilently(options)))
        );
    }

    localAuthSetup() {
        // This should only be called on app initialization
        // Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return this.getUser$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
            // If authenticated, response will be user object
            // If not authenticated, response will be 'false'
            this.loggedIn = !!response;
        });
    }

    login(redirectPath: string = '/app/dashboard') {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                appState: { target: redirectPath }
            });
        });
    }

    handleAuthCallback() {
        // Only the callback component should call this method
        // Call when app reloads after user logs in with Auth0
        let targetRoute: string; // Path to redirect to after login processsed
        const authComplete$ = this.handleRedirectCallback$.pipe(
            tap(cbRes => {
                // Get and set target redirect route from callback results
                targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/app/dashboard';
            }),
            concatMap(() => {
                // Redirect callback complete; get user and login status
                return combineLatest(
                    this.getUser$(),
                    this.isAuthenticated$,
                    this.getTokenSilently$()
                );
            }),
            // Tips from freecodecamp.org/news/blitz-tips-rxjs-pipe-is-not-a-subscribe-125c89437a2c/
            concatMap(([user, loggedIn, token]) => this._http.post<any>(`${environment.api.url}/login`, JSON.stringify({
                    email: user.email,
                    client_key: token
                }), {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'X-Api-Key': environment.api.apiKey
                    })
                })
            ),
            catchError(err => throwError(err)),
            tap(userData => this.userDataSubject$.next(userData))
        );
        // Subscribe to authentication completion observable
        // Response will be an array of user, token, and login status
        authComplete$.subscribe(() => {
            // Redirect to target route after callback processing
            this._router.navigate([targetRoute]);
        })
    }

    logout() {
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log out
            client.logout({
                client_id: environment.auth0.clientId,
                returnTo: window.location.origin
            });
        });
    }
}