import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { AuthService } from 'app/services/auth.service';
import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
    selector     : 'vertical-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;


    // Private
    private _unsubscribeAll: Subject<any>;
    private _userData: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {AuthService} _authService
     * @param {Router} _router
     * 
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _authService: AuthService,
        private _router: Router,
        private _fuseNavigationService: FuseNavigationService,
        private _dialog: MatDialog
    )
    {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.updateAuth();

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    updateAuth(): void 
    {
        // Determine the navigation used by user group of logged user.
        const logoutNavigationItem:FuseNavigationItem = this._fuseNavigationService.getNavigationItem('logout');

        if (logoutNavigationItem) {
            logoutNavigationItem.function = () => {
                const dialogRef = this._dialog.open(DialogConfirmLogout);

                dialogRef.afterClosed().subscribe((result:any) => {
                    if (result) {
                        this._authService.logout();
                        this._router.navigate(['/']);
                    }
                });
        
            };
        }
    }
}

@Component({
    selector: 'dialog-confirm-logout',
    templateUrl: 'dialog-confirm-logout.html',
})
export class DialogConfirmLogout {
    buffer: string = null;
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmLogout>
    ) { }
}
