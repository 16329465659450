import { FuseNavigation } from '@fuse/types';
import { AuthService } from '../services/auth.service';
import  { Injector } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from 'app/app.component';

export const navigation: FuseNavigation[] = [
    // Main menu.
    {
        id       : 'applications',
        title    : '',
        // translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children: [
            {
                id: 'applications-system-administrator',
                type: 'group',
                title: '',
                children: [
                    {
                        id       : 'dashboard',
                        title    : 'Dashboard',
                        type     : 'item',
                        icon     : 'dashboard',
                        url      : 'app/dashboard'
                    },
                    {
                        id       : 'templates',
                        title    : 'Plantillas',
                        type     : 'item',
                        icon     : 'library_books',
                        url      : 'app/templates'
                    },
                    {
                        id       : 'documents',
                        title    : 'Documentos',
                        type     : 'item',
                        icon     : 'subject',
                        url      : 'app/documents'
                    },
                    {
                        id       : 'users',
                        title    : 'Usuarios',
                        type     : 'item',
                        icon     : 'people',
                        url      : 'app/users',
                    },
                    {
                        id       : 'business',
                        title    : 'Empresas',
                        type     : 'item',
                        icon     : 'business',
                        url      : 'app/business'
                    },
                    {
                        id       : 'signatories',
                        title    : 'Firmantes',
                        type     : 'item',
                        icon     : 'people',
                        url      : 'app/signatories'
                    },
                    {
                        id       : 'settings',
                        title    : 'Configuración',
                        type     : 'item',
                        icon     : 'settings',
                        url      : 'app/settings'
                    },
                    {
                        id       : 'logout',
                        title    : 'Salir del sistema',
                        type     : 'item',
                        icon     : 'exit_to_app'
                    }
                ]
            }
        ]
            /*
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : 'app/dashboard'
            },
            {
                id       : 'onlineVerify',
                title    : 'Transactions',
                type     : 'item',
                icon     : 'payment',
                url      : 'app/onlineVerify'
            },
            {
                id       : 'customerService',
                title    : 'Customer Service',
                type     : 'item',
                icon     : 'account_balance',
                // url      : 'app/customerService',
                function: () => {
                    window.open('https://paykii.atlassian.net/servicedesk/customer/portal/11');
                }
            },

            {
                id       : 'fileManager',
                title    : 'My Files',
                type     : 'item',
                icon     : 'folder',
                url      : 'app/fileManager',
            },
            {
                id       : 'logout',
                title    : 'Logout',
                type     : 'item',
                icon     : 'exit_to_app',
            },
        ] */
    }
];
