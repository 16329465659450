// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    debug     : true,
    auth0: {
        clientId: 'QMoWaU94Yl6MScFxZBXhjudm62mpUTpo',
        domain: 'firmawp.auth0.com', // e.g., you.auth0.com
        //responseType: 'token',
        audience: 'https://firmawp.auth0.com/userinfo', // e.g., http://localhost:3001
        redirectUri: 'http://localhost:4200/#/dashboard',
        returnTo: 'http://localhost:4200/',
        // redirectUri: 'http://paykii-admin.dev.webpoint.com.mx/#/callback',
        // returnTo: 'http://paykii-admin.dev.webpoint.com.mx/',
        scope: 'openid profile email'
    },
    api: {
        // url: 'https://8o1yrwjqo7.execute-api.us-east-2.amazonaws.com/dev',
        // apiKey: 'Hsy7As9YaZ3M9WIb5Rp982hVRu4rW15p9gJDIqHT'
        url: 'https://0h9q1gdpg1.execute-api.us-east-1.amazonaws.com/v1',
        apiKey: 'zFD8FC9J998aTi49Wq0Sh8DQYYf4pruj6MuID3Ov'
    },
    amazonS3:{
        accessKeyId: 'AKIA3XLVOGBZ4VZ6QUG7',
        secretAccessKey: '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',
        bucket: 'firma-admin-development'
    },
    region: 'us-east-1'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
